<template>
  <div>
    <UsedCuponsFilter v-if="$can('filter', 'module_system_settings.settings.used_cupons')" :loading="loading"
      @searchFilter="searchFilter" @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Used Cupons" iconTable="BookmarkIcon" />
      <div class="m-1">
        <UsedCuponsEdit v-if="isEditUsedCuponsSidebarActive" :is-edit-used-cupons-sidebar-active.sync="isEditUsedCuponsSidebarActive
          " :itemEdit="itemEdit" @editUsedCuponsSuccess="editUsedCuponsSuccess" />
        <UsedCuponsAdd :is-add-new-used-cupons-sidebar-active.sync="isAddNewUsedCuponsSidebarActive
          " @createUsedCupons="createUsedCupons" />
        <seeCupons v-if="closeSeeCupons" :campaign="cupons" @closeSeeCupons="closeSeeCupons = false" />
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Show") }}</label>
            <v-select v-model="perPage" :options="perPageOptions" @change="changePerPage" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button v-if="$can('store', 'module_system_settings.settings.used_cupons')"
                @click="isAddNewUsedCuponsSidebarActive = true" :disabled="loading" variant="primary">
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} {{ $t("Used Cupons") }}</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
        show-empty empty-text="No matching records found">
        <template #cell(name)="data">
          {{ data.item.name.split("-").join(" ") }}
        </template>
        <template #cell(type)="data">
          {{ data.item.type == "value" ? "$" : "%" }}
        </template>
        <template #cell(facilities)="data">
          <div class="facilities">
            <b-badge pill class="font-small-2 facility" v-for="({ name }, index) in data.item.facilities"
              :key="`${name}-${index}`" variant="primary">
              {{ name }}
            </b-badge>
          </div>
        </template>
        <template #cell(message)="data">
          <div style="width: 10vw;">
            <span>
              {{ data.item.message }}
            </span>
          </div>
        </template>
        <template #cell(end_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ new Date(data.item.end_date).toLocaleString() }}
            </span>
          </div>
        </template>
        <template #cell(created_at)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ new Date(data.item.created_at).toLocaleString() }}
            </span>
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button v-if="$can('update', 'module_system_settings.settings.consult_price')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'See Page'" @click="seeLanding(data.item)">
            <feather-icon icon="MessageSquareIcon" />
          </b-button>

          <b-button v-if="$can('update', 'module_system_settings.settings.consult_price')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'Update'" @click="editUsedCupons(data.item)">
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button v-if="$can('update', 'module_system_settings.settings.consult_price')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
            v-b-tooltip.hover.top="'See Cupons'" @click="seeUsedCupons(data.item)">
            <feather-icon icon="BookIcon" />
          </b-button>

          <!-- <b-button
            v-if="$can('destroy', 'module_system_settings.settings.consult_price')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteUsedCupons(data.item)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button> -->
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

import axiosD from "@/services/admin/consults/usedCupons";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import UsedCuponsEdit from "./UsedCuponsEdit.vue";
import UsedCuponsAdd from "./UsedCuponsAdd.vue";
import UsedCuponsFilter from "./UsedCuponsFilter.vue";
import seeCupons from "./seeCupons.vue";

import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    UsedCuponsEdit,
    UsedCuponsAdd,
    UsedCuponsFilter,
    ToastNotification,
    seeCupons,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      closeSeeCupons: false,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "id", sortable: true, class: "th-class-adjusted" },
        { key: "name", sortable: true, class: "th-class-adjusted" },
        // A column that needs custom formatting
        { key: "description", sortable: true, class: "th-class-adjusted" },
        { key: "message", sortable: true, class: "th-class-adjusted-message" },
        { key: "quantity", sortable: true, class: "th-class-adjusted" },
        { key: "cupons_per_patient", sortable: true, class: "th-class-adjusted" },
        { key: "type", sortable: true, class: "th-class-adjusted" },
        { key: "value", sortable: true, class: "th-class-adjusted" },
        { key: "facilities", sortable: true, class: "th-class-adjusted" },
        { key: "created_at", sortable: true, class: "th-class-adjusted" },
        { key: "end_date", sortable: true, class: "th-class-adjusted" },
        { key: "actions", label: "Actions", class: "th-class-adjusted" },
      ],
      items: null,
      loading: false,
      cupons: [],
      isEditUsedCuponsSidebarActive: false,
      isAddNewUsedCuponsSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1
      },
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      UsedCuponsFilter: null,
      filtro: false,
      stateFilter: null,
    };
  },
  mounted() {
    this.getUsedCupons();
  },
  methods: {
    changePerPage() {
      if (!this.filtro) {
        this.getUsedCupons(this.perPage == undefined ? 10 : this.perPage);
      } else {
        this.searchFilter(this.perPage == undefined ? 10 : this.perPage, this.stateFilter);
      }
    },
    getUsedCupons() {
      axiosD
        .usedCuponList(10)
        .then(({ registro: { data, total, current_page, per_page, ...res } }) => {
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.perPage = per_page;
          this.currentPage.page = current_page;
        });
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosD
          .usedCuponPagination(this.perPage, page)
          .then(({ registro: { data, total, current_page, per_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.perPage = per_page;
            this.currentPage.page = current_page;
          });
      } else {
        axiosD
          .usedCuponFilter(
            this.UsedCuponsFilter,
            this.perPage,
            page
          )
          .then(({ registro: { data, total, current_page, per_page, ...res } }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.perPage = per_page;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    createUsedCupons(value) {
      if (value) {
        this.$refs.toast.success("UsedCupons added successfully");
      } else {
        this.$refs.toast.danger("UsedCupons not added");
      }
      this.isAddNewUsedCuponsSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    seeLanding(campaign) {
      const newUrl = (window.location.href).split("web-settings")[0]
      window.open(`${newUrl}ask-cupon/${campaign.name}`, '_blank')
    },
    editUsedCupons(item) {
      this.isEditUsedCuponsSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    seeUsedCupons(campaigns_cupons) {
      this.closeSeeCupons = true;
      this.cupons = campaigns_cupons
    },
    editUsedCuponsSuccess(value) {
      if (value) {
        this.$refs.toast.success("UsedCupons updated successfully");
      } else {
        this.$refs.toast.error("Error updating UsedCupons");
      }
      this.pageChanged(this.currentPage.page);
      this.isEditUsedCuponsSidebarActive = false;
    },
    searchFilter(perPage = 10, value) {
      if (!this.filtro) return this.getUsedCupons();
      this.perPage = perPage;
      this.loading = true;
      this.UsedCuponsFilter = value;
      axiosD
        .usedCuponFilter(value, perPage, 1)
        .then(({ registro: { data, total, current_page, per_page, ...res } }) => {
          this.loading = false;
          this.perPage = per_page;
          this.items = data;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteUsedCupons(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosD.usedCuponDelete(item.id).then((response) => {
            this.$refs.toast.success(
              "UsedCupons has been deleted successfully"
            );
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style scoped>
.facilities {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1vw;
  padding: 1vw;
}

.th-class-adjusted {
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>