import { userAxios } from "@/services";

const usedCuponList = async (perPage) => {
  try {
    return await userAxios
      .get(`campaigns-cupons/index/${perPage}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const usedCuponCreate = async (data) => {
  try {
    return await userAxios
      .post("campaigns-cupons", data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const usedCuponUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`campaigns-cupons/${id}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const usedCuponDelete = async (id) => {
  try {
    return await userAxios
      .delete(`campaigns-cupons/${id}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const usedCuponPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`campaigns-cupons/index/${perPage}?page=${page}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const listCuponPagination = async (id, perPage, page) => {
  try {
    return await userAxios
      .get(`campaigns-cupons/detail/${id}/${perPage}?page=${page}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const getCupon = async (data) => {
  try {
    return await userAxios
      .post(`campaigns-cupons/get-cupon`,data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const getCampaign = async (data) => {
  try {
    return await userAxios
      .post(`campaigns-cupons/get-campaign`,data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const usedCuponFilter = async (data, perPage, page) => {
  try {
    return await userAxios
      .post(`campaigns-cupons/filter/${perPage}?page=${page}`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};


export default {
  usedCuponList,
  usedCuponCreate,
  usedCuponUpdate,
  usedCuponDelete,
  usedCuponPagination,
  usedCuponFilter,
  listCuponPagination,
  getCupon,
  getCampaign
};
