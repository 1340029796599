<template>
  <b-sidebar id="add-new-consult-price-sidebar" :visible="isAddNewUsedCuponsSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-used-cupons-sidebar-active', val)
      ">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">{{ $t("Add") }} {{ $t('New') }} Coupons</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input id="name" v-model="stateData.name" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Used Cupons name" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="description" rules="required">
            <b-form-group :label="$t('Form.Description')" label-for="description">
              <b-form-textarea id="description" v-model="stateData.description" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Used Cupons description" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="message" rules="required">
            <b-form-group :label="$t('Message')" label-for="message">
              <b-form-textarea id="message" v-model="stateData.message" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Used Cupons message" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- <validation-provider #default="validationContext" name="prefix" rules="required">
            <b-form-group :label="$t('Prefix')" label-for="prefix">
              <b-form-input id="prefix" v-model="stateData.prefix" autofocus
                :state="getValidationState(validationContext)" trim placeholder="Used Cupons prefix" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <validation-provider #default="validationContext" name="quantity" rules="required">
            <b-form-group :label="$t('Quantity')" label-for="quantity">
              <b-form-input id="quantity" v-model="stateData.quantity" autofocus
                :state="getValidationState(validationContext)" trim type="number" placeholder="Used Cupons quantity" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="cupons_per_patient`" rules="required">
            <b-form-group :label="$t('Cupons_per_patient')" label-for="cupons_per_patient">
              <b-form-input id="cupons_per_patient" v-model="stateData.cupons_per_patient" autofocus
                :state="getValidationState(validationContext)" trim type="number"
                placeholder="Used Cupons cupons_per_patient" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="end_date" rules="required">
            <b-form-group :label="$t('Form.End_date')" label-for="end_date">
              <flat-pickr id="date" v-model="stateData.end_date" :config="config" class="form-control"
                placeholder="MM-DD-YYYY" type="date" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="type" rules="required">
            <b-form-group :label="$t('Form.Type')" label-for="type">
              <v-select id="type" v-model="stateData.type" :state="getValidationState(validationContext)"
                :options="optionsType" :reduce="val => val.value" :clearable="false" label="text" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="value" rules="required">
            <b-form-group :label="$t('Form.Value')" label-for="value">
              <b-input-group :prepend="stateData.type == 'value' ? '$' : '%'" :label="$t('Form.Value')"
                label-for="value">
                <b-form-input id="value" v-model="stateData.value" autofocus
                  :state="getValidationState(validationContext)" type="number" trim placeholder="Used Cupons value" />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </validation-provider>
          
          <b-form-group>
            <b-form-checkbox v-model="stateData.is_review" :unchecked-value="false">
              Review
            </b-form-checkbox>
          </b-form-group>

          <validation-provider #default="{ errors }" name="Facility" rules="required">
            <b-form-group label="Facility" label-for="facility" style="width:100%;">
              <b-input-group>
                <v-select id="facility" v-model="stateData.facilities" :options="facilitiesOptions"
                  :reduce="(option) => option.id" :clearable="true" multiple label="name"
                  style="z-index:999;min-width:250px;" />
                <small class="text-danger">{{ errors[0] }}</small>
                <b-input-group-append v-if="stateData.facilities.length != facilitiesOptions.length">
                  <b-button variant="outline-primary" @click="selectAll()">Select All</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <!-- <validation-provider #default="validationContext" name="Status" rules="required">
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select id="status" v-model="stateData.status" :state="getValidationState(validationContext)"
                :options="optionsStatus" :reduce="val => val.value" :clearable="false" label="text" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
              :disabled="loading">
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="hide">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";
import axiosFacilities from "@/services/admin/facilities";
import flatPickr from "vue-flatpickr-component";

import axiosD from "@/services/admin/consults/usedCupons";

export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUsedCuponsSidebarActive",
    event: "update:is-add-new-used-cupons-sidebar-active",
  },
  props: {
    isAddNewUsedCuponsSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {

  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const facilitiesOptions = ref([]);
    const getFacility = () => {
      axiosFacilities.facilityList().then((res) => {
        facilitiesOptions.value = res.data;
      });
    };

    onMounted(() => {
      getFacility();
    });

    const optionsStatus = [
      { text: "Active", value: true },
      { text: "Inactive", value: false },
    ];

    const optionsDefault = [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ];

    const optionsType = [
      { text: "$", value: "value" },
      { text: "%", value: "percentage" },
    ];

    const config = {
      dateFormat: "Y-m-d",
      allowInput: true,
      enableTime: true,
    }

    const stateDataTem = {
      name: "",
      cupons_per_patient: 1,
      is_review: false,
      facilities: [],
      description: "",
      end_date: "",
      message: "",
      prefix: "",
      quantity: "",
      status: true,
      type: "",
      value: ""
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    function selectAll() {
      stateData.value.facilities = facilitiesOptions.value.map((f) => f.id)
    }

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      stateData.value.name = stateData.value.name.split(" ").join("-")
      stateData.value.status = true
      const data = {
        ...stateData.value,
      };
      axiosD
        .usedCuponCreate(data)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("createUsedCupons", true);
        })
        .catch(() => {
          loading.value = false;
          emit("createUsedCupons", false);
        });
    };
    return {
      optionsType,
      config,
      loading,
      stateData,
      optionsStatus,
      optionsDefault,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      facilitiesOptions,
      selectAll,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>