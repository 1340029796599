<template>
  <b-modal
    v-model="conditionCancel"
    @shown="onShown"
    @hide="onHide"
    hide-header
    centered
    size="xl"
  >
    <div class="stats-preview">
      <div class="stat-card" v-for="stat in stats" :key="stat.label">
        <h3>{{ stat.label }}</h3>
        <p>{{ stat.value }}</p>
      </div>
    </div>
    <b-table
      :items="items"
      :fields="fields"
      hover
      bordered
      responsive
      primary-key="id"
      class="position-relative"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(status)="data">
        {{ getStatus(data.item.status) }}
      </template>
      <template #cell(actions)="data">
        <p
          class="see-appointment"
          v-if="
            $can('update', 'module_system_settings.settings.consult_price') &&
            data.item.appointment
          "
          target="_blank"
          @click="getAppointment(data.item.appointment)"
        >
          See appointment
        </p>
      </template>
      <template #cell(updated_at)="data">
        {{ new Date(data.item.updated_at).toLocaleString() }}
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).toLocaleString() }}
      </template>
    </b-table>
    <PaginationTable
      :dataMeta="dataMeta"
      :totalUsers="totalUsers"
      :perPage="perPage"
      :currentPage="currentPage"
      @page-changed="pageChanged"
    />
  </b-modal>
</template>

<script>
import { BModal, BTable } from "bootstrap-vue";
import PaginationTable from "@/components/PaginationTable";
import axiosD from "@/services/admin/consults/usedCupons";

export default {
  components: {
    BTable,
    BModal,
    PaginationTable,
  },
  props: {
    campaign: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      conditionCancel: true,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      items: [],
      stats: [
        { label: "Claimed", value: 0 },
        { label: "Used", value: 0 }, // Puedes reemplazar estos valores con tus datos reales
        { label: "Total", value: 0 },
      ],
      currentPage: {
        page: 1,
      },
      perPage: 10,
      fields: [
        "id",
        { key: "cupon", sortable: true },
        { key: "ip_client", sortable: true },
        { key: "phone_number", sortable: true },
        { key: "status", sortable: true },
        { key: "updated_at", sortable: true },
        { key: "created_at", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },
  methods: {
    onHide() {
      this.$emit("closeSeeCupons", false);
    },
    getStatus(status) {
      return status ? "Redeemed" : "Unclaimed";
    },
    onShown() {
      this.pageChanged(1);
    },
    getAppointment(appointment) {
      if (!appointment) return;
      window.open(`/recipe-patient/${appointment.id}`, "_blank");
    },
    pageChanged(page) {
      axiosD
        .listCuponPagination(this.campaign.id, this.perPage, page)
        .then(
          ({
            registro: {
              data,
              total,
              current_page,
              per_page,
              claimed,
              used,
              ...res
            },
          }) => {
            this.stats[0].value = claimed;
            this.stats[1].value = used;
            this.stats[2].value = total;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.perPage = per_page;
            this.currentPage.page = current_page;
          }
        );
    },
  },
};
</script>

<style>
.see-appointment {
  text-decoration: underline;
  color: var(--primary);
}

.stats-preview {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.stat-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.stat-card:hover {
  transform: scale(1.05);
}

.stat-card h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.stat-card p {
  margin: 5px 0 0;
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}
</style>
